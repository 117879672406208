<template>
    <div id="content" class="label resume">
        <div class="content full">
            <div class="resume-actions">
                <button class="btn ico alerts"></button>
                <button class="btn ico search"></button>
                <input type="text" class="input-search" value="" />
            </div>
            <div class="resume-content hide-scrollbar">
                <div v-for="(day, index) in resume" class="column-day" :class="{ selected: day.isToday }">
                    <div class="title month">{{ day.date.format('MMMM') }}</div>
                    <div class="title day">{{ day.date.format('dddd DD') }}</div>
                    <ul class="list hide-scrollbar">
                        <li v-if="Object.keys(day.product).length == 0" class="empty">{{ $t('label.resume.column_empty') }}</li>
                        <li v-for="(product, id) in day.product" class="item" :class="{ expired: product.expired, 'to-expire': product.toExpire, alert: product.alert }">
                            <span class="hour" :class="{ pulse: product.expired }">{{ product.end_date.format('HH:mm') }}</span>
                            <span class="name">{{ product.name }}</span>
                            <span class="quantity">{{ product.quantity }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LabelResume',
    data() {
        return {}
    },
    computed: {
        resume() {
            return this.$store.getters['label/getResume']
        }
    },
    watch: {
        $route: 'load'
    },
    methods: {
        load(refresh = true) {
            var self = this
            self.$store.dispatch('label/loadLabelsResume', {})

            self.$bar.reset()
            self.$bar.addAction('back', {
                callback: function() {
                    self.$router.push('/label')
                },
                label: self.$t('menu_aux.action.back')
            })
        }
    },
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.label.resume {
    > .content {
        .resume-actions {
            width: 100%;
            height: 50px;

            .btn {
                margin: 5px 10px 5px 0;
                float: right;

                &.search {
                    @include background($image: url(/images/search_dark.svg), $color: transparent, $size: 25px);
                    @include opacity(0.6);

                    &.active {
                        background-image: url(/images/search_primary.svg);
                    }
                }
                &.alerts {
                    @include background($image: url(/images/bell_dark.svg), $color: transparent, $size: 25px);
                    @include opacity(0.6);

                    &.active {
                        background-image: url(/images/bell_primary.svg);
                    }
                }
            }

            .input-search {
                display: none;
                float: right;
                height: 40px;
                margin: 5px 0px;
                background-color: transparent;
            }
        }

        .resume-content {
            width: 100%;
            height: calc(100% - 50px);
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
        }

        .column-day {
            @include display-inline-flex();
            @include flex-direction(column);
            height: 100%;
            width: 275px;
            text-align: center;
            padding-left: 5px;

            &:first-child {
                border-left: none;
            }

            .title {
                @include font-size(xl);
                line-height: 1;
                text-align: center;
                color: $main-dark;
                text-transform: capitalize;
                font-family: $conden-light;
                position: relative;

                &.month {
                    @include font-size(sm);
                    color: #bbbbbb;
                    text-transform: uppercase;
                    font-family: $conden-bold;
                    padding-top: 12px;
                }
            }

            .list {
                margin: 6px 6px 0 6px;
                max-height: calc(100% - 60px);
                overflow: auto;

                .empty {
                    @include font-size(sm);
                    color: #bbb;
                    padding-top: 18px;
                }

                .item {
                    @include display-flex();
                    @include align-items(center);
                    @include justify-content(start);
                    @include flex-wrap(wrap);
                    margin: 6px 3px;
                    background-color: #fff;
                    padding: 6px 3px;
                    border-left: 2px solid #dadada;

                    .hour {
                        @include font-size(m);
                        line-height: 1;
                        font-family: $text;
                        display: block;
                        width: 60px;
                        text-align: center;
                        margin-right: 6px;
                    }
                    .quantity {
                        @include border-radius(20px);
                        @include width-fit-content();
                        display: inline-block;
                        min-width: 20px;
                        max-width: 25px;
                        height: 20px;
                        background-color: #eee;
                        text-align: center;
                        font-family: $conden-bold;
                        margin-left: 6px;
                    }
                    .name {
                        display: inline-block;
                        max-width: calc(100% - 30px - 6px - 60px - 6px);
                        width: 100%;
                        word-break: break-all;
                    }
                }

                .item.alert {
                    border-color: $main;

                    .hour {
                        color: $main;
                    }
                    .quantity {
                        background-color: $main;
                        color: #fff;
                    }
                }

                .item.to-expire {
                    border-color: $warning;

                    .hour {
                        color: $warning;
                    }
                    .quantity {
                        background-color: $warning;
                        color: #fff;
                    }
                }

                .item.expired {
                    border-color: $error;

                    .hour {
                        @include font-size(ml);
                        line-height: 1;
                        color: $error;
                    }
                    .quantity {
                        background-color: $error;
                        color: #fff;
                    }
                }
                .item.expired + .item:not(.expired) {
                    margin-top: 24px !important;
                }
            }
        }
        .column-day.selected {
            @include border-radius(6px);
            background-color: #f3f3f3;
            background-clip: content-box;

            .title.day {
                font-family: $conden;
                color: $main;
            }
            .title.month {
                color: $main-light;
            }

            .list {
            }
        }
        .column-day.show-alerts {
            .list {
                .item:not(.alert) {
                    display: none;
                }
            }
        }

        .resume-detail {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 40px);
            background-color: #f9f9f9;

            .column-detail {
                height: inherit;
                margin-right: -5px;
                max-height: calc(100% - 60px);

                &.coming,
                &.past {
                    width: 50%;
                }
                &.past {
                    .list {
                        // @include flex-wrap(wrap-reverse);
                        // transform: scaleY(-1);

                        .item {
                            // transform: scaleY(-1);
                        }
                    }
                }
                &.all {
                    @include display-flex();
                    width: 50%;
                    margin: 0 auto;
                }

                .title {
                    text-transform: initial;
                }
            }

            .title {
                @include font-size(l);
                line-height: 1;
                text-align: center;
                color: $main-dark;
                text-transform: capitalize;
                font-family: $conden-light;
                position: relative;

                &.month {
                    @include font-size(ml);
                    color: #bbbbbb;
                    text-transform: uppercase;
                    font-family: $conden-light;
                    padding-top: 6px;
                }
                &.day {
                    text-transform: uppercase;
                    font-family: $conden-bold;
                    margin-bottom: 12px;
                }
            }

            .list {
                @include display-inline-flex();
                @include flex-wrap(wrap);

                .item,
                .empty {
                    margin: 6px auto !important;
                    max-width: 300px;
                    width: 100%;
                }
            }
        }
    }
}
</style>
