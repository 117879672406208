<template>
    <div id="content" class="elabel" :class="[$mq]">
        <template v-if="preview">
            <div class="full-preview" @click="maximizePreview()">
                <div class="close-preview" @click="maximizePreview()"></div>
                <Preview :data="preview" :maximized="true"></Preview>

                <div class="date">{{ $t('label.print.print_date') }}: {{ print_date }}</div>
                <!-- <div class="btn-close" @click="maximizePreview()">{{ $t('label.print.minimize') }}</div> -->
            </div>
        </template>
        <template v-else>
            <div class="calendar-navigation-container">
                <calendar-navigation @calendarOpen="calendarState" @select="changeDate" :selected_date="formatDateForCalendarNavigation()" :future_days="1" :past_days="2"></calendar-navigation>
            </div>
            <transition name="slide-fade">
                <div class="filters" v-if="showFilters">
                    <!-- <select class="item date" v-model="date">
                    <option v-for="(value, key) in dates" :value="key">{{ value }}</option>
                </select> -->
                    <!-- <div class="item expand" :class="{ expanded: expanded }" @click="toggle()"></div> -->
                    <div class="advance">
                        <select class="item state" v-model="filters.state">
                            <option value="">{{ $t('label.states.all') }}</option>
                            <option v-for="state in states" :value="state.id">{{ state.name }}</option>
                        </select>
                        <select class="item category" v-model="filters.category">
                            <option value="">{{ $t('label.categories.all') }}</option>
                            <option v-for="category in categories" :value="category.id">{{ category.name }}</option>
                        </select>
                        <div class="item product">
                            <input type="text" v-model="filters.product" />
                            <div v-if="filters.product != ''" class="close" @click="cleanSearch()"></div>
                        </div>
                        <div class="item bttn" :class="{ disabled: !filterChanges }" @click="search()">{{ $t('menu_aux.action.search') }}</div>
                    </div>
                </div>
            </transition>
            <div class="filter-btn" @click="showFilters = !showFilters">
                {{ !showFilters ? $t('elabel.show_filter') : $t('elabel.hide_filter') }}
            </div>

            <div class="list" ref="list" :class="[{ expanded: expanded, empty: results == 0 || offline }, { 'filters-opened': showFilters }, { calendarOpened: calendarIsVisible }]">
                <template v-if="offline">
                    <EmptyPage :title="$t('empty_pages.connection_description')" :description="$t('empty_pages.connection_title')" class="error"></EmptyPage>
                </template>
                <template v-else>
                    <template v-if="results == 0">
                        <EmptyPage :title="$t('empty_pages.generic_title')" :description="$t('empty_pages.label_description')"></EmptyPage>
                    </template>
                    <template v-else>
                        <SingleLabel v-for="(label, key) in labels" :key="key" :label="label" @click.native="previewLabel(label)" type="preview"></SingleLabel>
                        <div class="pagination">
                            <div v-if="page > 1" class="item prev" @click="page_prev()"></div>
                            <div v-if="num_items * page < results" class="item next" @click="page_next()"></div>
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Preview from '@/components/domain/label/preview.vue'
import SingleLabel from '@/components/domain/label/singleLabel.vue'
import EmptyPage from '../components/layout/EmptyPage.vue'
import Label from './Label.vue'
import CalendarNavigation from '@/components/layout/CalendarNavigation.vue'

export default {
    name: 'DigitalLabel',
    components: { Preview, SingleLabel, EmptyPage, Label, CalendarNavigation },
    data() {
        return {
            calendarIsVisible: false,
            date: 'today',
            filters: {
                state: '',
                category: '',
                product: ''
            },
            dates: {
                today: i18n.t('label.filter.today'),
                yesterday: i18n.t('label.filter.yesterday'),
                week: i18n.t('label.filter.week'),
                month: i18n.t('label.filter.month'),
                quarter: i18n.t('label.filter.quarter')
                // custom: i18n.t('label.filter.custom')
            },
            expanded: false,
            preview: false,
            print_date: false,
            offline: false,
            num_items: 20,
            page: 1,
            showFilters: false,
            compareFilters: {
                state: '',
                category: '',
                product: ''
            },
            filterChanges: false
        }
    },
    computed: {
        categories() {
            return this.$store.getters['label/getSortedCategories']
        },
        states() {
            return this.$store.getters['label/getLabelStates']()
        },
        labels() {
            var labels = []
            var raw = this.$store.getters['label/getLabelsPrinted'].labels

            for (var key in raw) {
                var label = raw[key]
                label.product = label.reference
                label.current_print_time = label.date
                labels.push(label)
            }
            return labels
        },
        results() {
            return this.$store.getters['label/getLabelsPrinted'].results
        }
    },
    methods: {
        calendarState(show) {
            this.calendarIsVisible = show
        },
        formatDateForCalendarNavigation() {
            var date = this.date
            if (date == 'today') {
                return moment().format('YYYY-MM-DD')
            } else if (date == 'yesterday') {
                return moment()
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD')
            } else if (date == 'week') {
                return moment()
                    .subtract(1, 'weeks')
                    .format('YYYY-MM-DD')
            } else if (date == 'month') {
                return moment()
                    .subtract(1, 'months')
                    .format('YYYY-MM-DD')
            } else if (date == 'quarter') {
                return moment()
                    .subtract(3, 'months')
                    .format('YYYY-MM-DD')
            } else {
                return moment(date).format('YYYY-MM-DD')
            }
        },
        load(refresh = true) {
            this.search()
            // If portrait, do not show filters expanded
            this.expanded = !['portrait'].includes(this.$mq)
            this.num_items = ['portrait'].includes(this.$mq) ? 20 : 3 * 10
        },
        toggle() {
            this.expanded = !this.expanded
        },
        cleanSearch() {
            this.product = ''
        },
        page_prev() {
            this.page -= 1
            this.search()
        },
        page_next() {
            this.page += 1
            this.search()
        },
        changeDate(date) {
            this.date = date
            this.search()
        },
        search() {
            if (fnCheckConnection()) {
                this.offline = false
                var self = this
                var params = {
                    start_date: this.date,
                    end_date: this.date,
                    category: this.filters.category,
                    states: this.filters.state,
                    name: this.filters.product,
                    number_pages: this.page,
                    number_items: this.num_items
                }
                this.$overlay.loading()
                this.$store.dispatch('label/loadLabelsPrinted', params).then(function() {
                    self.$overlay.hide()
                    var scroll = {
                        container: self.$refs.list,
                        target: document.querySelectorAll('.elabel .list')[0],
                        offset: -400
                    }

                    self.$root.scrollTo(scroll)
                })
                this.compareFilters.category = params.category
                this.compareFilters.state = params.states
                this.compareFilters.product = params.name
            } else {
                this.offline = true
            }
        },
        maximizePreview() {
            this.preview = !this.preview
        },
        previewLabel(label) {
            var self = this
            var reference = this.$store.getters['label/getLabel'](label.reference)
            var format = this.$store.getters['printer/getTemplates'](reference.states[label.state].format)
            var values = JSON.parse(format.data)
            log('--- previewLabel ---')
            log('label', label)
            log('reference', reference)
            log('format', format)
            if (label.preview) {
                try {
                    var params = JSON.parse(label.data)
                    this.preview = JSON.parse(label.preview)

                    var oLocation = this.$store.getters['loginUser/getLocalLocation']
                    params['account'] = oLocation.account_name
                    params['location'] = oLocation.name
                    params['quantity'] = self.num_copies
                    params['city'] = oLocation.city
                    params['address'] = oLocation.address
                    params['loc_state'] = oLocation.state
                    params['cp'] = oLocation.cp

                    log(params)
                    log(this.preview)

                    for (var key in this.preview.fields) {
                        var field = this.preview.fields[key]
                        var value = field.f_id ? values[field.f_id] : field.value
                        const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                        let m
                        let aux_value = value
                        while ((m = regex.exec(value)) !== null) {
                            // This is necessary to avoid infinite loops with zero-width matches
                            if (m.index === regex.lastIndex) {
                                regex.lastIndex++
                            }

                            // The result can be accessed through the `m`-variable.
                            m.forEach((match, groupIndex) => {
                                var m_index = match.replace(/[\{\}\[\]]/gi, '')
                                let name = this.$t('label.preview.' + m_index)
                                if (name.indexOf('label.preview.') != -1) {
                                    name = m_index
                                }
                                log('Match: ', match)
                                log('Index: ', m_index)
                                if (!Object.keys(params).includes(m_index)) {
                                    const regex2 = /(_d[0-9]|_day|_timestamp|_time)/gi
                                    let n
                                    while ((n = regex2.exec(m_index)) !== null) {
                                        // This is necessary to avoid infinite loops with zero-width matches
                                        if (n.m_index === regex2.lastIndex) {
                                            regex2.lastIndex++
                                        }

                                        // The result can be accessed through the `m`-variable.
                                        n.forEach((match2, groupIndex) => {
                                            var keyValue = m_index.replace(match2, '')
                                            if (Object.keys(params).includes(keyValue)) {
                                                var d = moment(params[keyValue], 'DD/MM/YYYY HH:mm')
                                                if (!d.isValid()) {
                                                    d = moment(parseInt(params[keyValue]) * 1000)
                                                }
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue + '_timestamp'] = parseInt(d.valueOf() / 1000).toString()
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            } else if (Object.keys(params).includes(keyValue + '_timestamp')) {
                                                var d = moment(parseInt(params[keyValue + '_timestamp']) * 1000)
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue] = d.format('DD/MM/YYYY HH:mm')
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            }
                                        })
                                    }

                                    if (!Object.keys(params).includes(m_index)) {
                                        params[m_index] = ''
                                    }
                                }

                                if (m_index != '' && Object.keys(params).includes(m_index)) {
                                    aux_value = aux_value.replace(match, params[m_index])
                                }
                            })
                        }
                        self.preview.fields[key].value = aux_value
                    }
                    this.$snackbar.close()

                    this.print_date = params.print_date
                } catch (e) {
                    this.$snackbar.error({ message: i18n.t('label.preview.invalid'), closeable: true })
                }
            } else {
                this.$snackbar.error({ message: i18n.t('label.preview.invalid'), closeable: true })
            }
        }
    },
    created() {
        this.load()
    },
    beforeRouteLeave(to, from, next) {
        this.$snackbar.close()
        this.$popup.close()
        next()
    },
    watch: {
        filters: {
            handler: function(val, oldVal) {
                if (JSON.stringify(this.filters) != JSON.stringify(this.compareFilters)) {
                    this.filterChanges = true
                } else {
                    this.filterChanges = false
                }
            },
            deep: true
        },
        compareFilters: {
            handler: function(val, oldVal) {
                if (JSON.stringify(this.filters) != JSON.stringify(this.compareFilters)) {
                    this.filterChanges = true
                } else {
                    this.filterChanges = false
                }
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#content.elabel {
    .full-preview {
        @include display-flex();
        @include justify-content();
        @include align-items(center);
        @include flex-direction(column);
        width: 100%;
        height: 100%;

        .btn-close {
            @include border-radius(4px);
            @include interaction;
            background-color: #fff;
            opacity: 0.7;
            margin-top: 25px;
            padding: 10px 20px;
            color: #444;
            font-family: $text-sbold;

            @include bgHover(darken(#fff, 5%));
        }

        .close-preview {
            position: absolute;
            @include background($image: img('minimize.svg'), $position: center center, $size: 20px, $color: #fff);
            @include border-radius(4px);
            @include interaction();
            width: 40px;
            height: 40px;
            top: 20px;
            right: 20px;
        }

        .date {
            @include font-size(s);
            font-family: $text-light;
            color: $neutro-s80;
            width: 100%;
            display: inline-block;
            bottom: 0px;
            padding: 10px 0;
            text-align: center;
        }
    }
    .calendar-navigation-container {
        padding: 25px 25px;
    }
    .filter-btn {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include background($image: img('filter_elabel.svg'), $position: left center, $size: 30px);
        @include font-size(sm);
        @extend .interaction;
        color: $neutro-s80;
        width: fit-content;
        font-family: $text-thin;
        height: 50px;
        padding: 20px 40px;
        margin: 0 auto;
        transition-duration: 0.2s;
    }

    .slide-fade-enter-active {
        transition: all 0.2s ease;
    }
    .slide-fade-leave-active {
        transition: all 0.2s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(-50px);
        opacity: 0;
    }

    .filters {
        padding: 0px 5px;
        overflow: hidden;

        .item {
            @include border-radius(5px);
            padding: 0px 10px;
            width: calc(20% - 10px);
            margin: 5px 5px;
            border: none;
            padding-left: 50px;
            height: 45px;
            line-height: 45px;
            appearance: auto;
            display: inline-block;
            float: left;
            background-color: #fff;
            position: relative;
            cursor: pointer;
            font-family: $text-light;
            color: $neutro-s80;

            &.date {
                @include background($image: img('input_datetime_cccccc.svg'), $position: left 15px center, $size: 25px);
            }
            &.expand {
                width: 45px;
                background: #fff;
                padding: 0;
                @include background($image: img('down_dark.svg'), $position: center, $size: 25px);
                float: right;
                display: none;

                &.expanded {
                    @include background($image: img('up_dark.svg'), $position: center, $size: 25px);
                }
            }
            &.category {
                @include background($image: img('filter_neutro.svg'), $position: left 15px center, $size: 25px);
            }
            &.state {
                @include background($image: img('label_neutro.svg'), $position: left 15px center, $size: 25px);
            }
            &.product {
                @include background($image: img('search_neutro.svg'), $position: left 15px center, $size: 25px);

                input {
                    width: calc(100% - 20px);
                    float: left;
                    border: none;
                    padding: 0;
                    height: 45px;
                    margin: 0;
                    font-family: $text-light;
                    color: $neutro-s80;
                }

                .close {
                    float: left;
                    @include border-radius(4px);
                    @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                    width: 33px;
                    height: 33px;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    background-color: $neutro-t50;
                    cursor: pointer;
                }
            }
            &.bttn {
                background: $main;
                text-align: center;
                color: #fff !important;
                padding: 0 10px;
                font-family: $text-bold !important;

                &.disabled {
                    background-color: $main-t80 !important;
                    cursor: not-allowed;
                    color: $main-t40 !important;
                    font-family: $text-bold !important;
                }
            }
        }

        .advance {
            display: inline-block;
            margin-top: 0;
            float: left;
            padding: 0;
            width: 100%;

            .item {
                width: calc(25% - 10px);
            }
        }
    }

    .list {
        height: calc(100% - 140px);
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;
        padding: 0 25px 15px 25px;

        @media (max-width: 1200px) {
            // grid-template-columns: 1fr 1fr;
        }

        &.empty {
            grid-template-columns: 1fr;
            display: block;
        }

        .pagination {
            width: 100%;
            padding: 0px;
            display: block;
            grid-column: 1 / 4;

            .item {
                width: calc(50% - 10px);
                height: 45px;
                margin: 0 5px;
                float: left;
                @include border-radius(4px);

                &.next {
                    @include background($image: img('right_dark.svg'), $position: center center, $size: 20px, $color: $main-t80);
                    float: right;
                }
                &.prev {
                    @include background($image: img('left_dark.svg'), $position: center center, $size: 20px, $color: $main-t80);
                }
            }
        }
        &.filters-opened {
            height: calc(100% - 190px);
        }
        &.calendarOpened {
            height: calc(100% - 500px);
        }
    }
    &.landscape {
        .calendar-navigation-container {
            padding-bottom: 10px;
        }
        .filters {
            padding: 0px 20px;
        }
    }
    &.desktop {
        .filters {
            padding: 0px 20px;
        }
        .calendar-navigation-container {
            padding-bottom: 10px;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.portrait #content.elabel {
    .full-preview {
        padding: 10px 0;
        padding-top: 70px;

        .date {
            // width: calc(100% - 40px);
            // display: inline-block;
            // position: absolute;
            // left: 20px;
            // top: 20px;
            // padding: 10px 0;
            // text-align: left;
        }
    }
    .calendar-navigation-container {
        padding: 10px 2px;
        padding-bottom: 0px;
    }
    .filter-btn {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include background($image: img('filter_elabel.svg'), $position: left center, $size: 30px);
        @include font-size(sm);
        @extend .interaction;
        color: $neutro-s80;
        width: fit-content;
        font-family: $text-light;
        height: 50px;
        padding: 20px 40px;
        margin: 0 auto;
        transition-duration: 0.2s;
    }

    .slide-fade-enter-active {
        transition: all 0.2s ease;
    }
    .slide-fade-leave-active {
        transition: all 0.2s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(-50px);
        opacity: 0;
    }
    .filters {
        padding: 10px;
        padding-top: 0px;

        .item {
            @include font-size(sm);
            width: 100%;
            max-width: 100%;
            margin: 5px 0;
            font-family: $text;
            color: $neutro-s80;

            &.date {
                width: calc(100% - 45px - 20px);
                margin-right: 20px;
            }
            &.expand {
                width: 45px;
                display: block;
            }
        }
        .advance {
            display: block;
            width: 100%;
            margin-top: 10px;
            float: left;
        }
    }

    .list {
        height: calc(100% - 140px);
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;
        padding: 0 10px 15px 10px;

        &::-webkit-scrollbar {
            display: none;
        }

        &.expanded {
            height: calc(100% - 130px);
        }
        &.filters-opened {
            height: calc(100% - 355px);

            &.calendarOpened {
                height: calc(100% - 745px);
            }
        }
        &.calendarOpened {
            height: calc(100% - 500px);
        }
    }

    .pagination {
        width: 100%;
        padding: 0px;
        grid-column: 1;

        .item {
            width: calc(50% - 10px);

            &.next {
            }
            &.prev {
            }
        }
    }
}

.landscape #content.elabel {
    .full-preview {
        padding: 10px 0;
        padding-top: 70px;

        .date {
            // width: calc(100% - 40px);
            // display: inline-block;
            // position: absolute;
            // left: 20px;
            // top: 20px;
            // padding: 10px 0;
            // text-align: left;
        }
    }

    .list {
        height: calc(100% - 140px);
        overflow: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-content: start;
        column-gap: 15px;
        row-gap: 15px;
        align-items: start;
        padding: 0 10px 15px 10px;

        &.empty {
            grid-template-columns: 1fr;
        }

        &::-webkit-scrollbar {
            display: none;
        }
        &.filters-opened {
            height: calc(100% - 190px);

            &.calendarOpened {
                height: calc(100% - 560px);
            }
        }
        &.calendarOpened {
            height: calc(100% - 500px);
        }
    }

    .pagination {
        width: 100%;
        padding: 0px;
        grid-column: 1/3;

        .item {
            width: calc(50% - 10px);

            &.next {
            }
            &.prev {
            }
        }
    }
}
</style>
