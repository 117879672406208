<template>
    <div class="input-container">
        <input ref="inputText" type="text" v-bind:value="value" @input="updateValue($event.target.value)" @focus="handleFocus" @blur="handleBlur" class="input-ticketing" />
    </div>
</template>

<script>
export default {
    props: {
        givenValue: { type: String, default: '' }
    },
    data() {
        return {
            value: this.givenValue,
            timer: false
        }
    },
    computed: {},
    methods: {
        updateValue: function(value) {
            var self = this
            this.$emit('blockNext', true)
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }

            this.value = value
            this.timer = setTimeout(() => {
                self.$emit('blockNext', false)
                self.$emit('input', value)
            }, 400)
        },

        handleFocus(event, atElement) {
            let id = this.$refs.inputText
            atElement = typeof atElement === 'undefined' ? false : atElement

            this.$emit('handleFocus', true)
            this.$root.handleFocusKeyboard(this)
        },
        handleBlur(e) {
            this.$emit('handleFocus', false)
            this.$root.handleBlurKeyboard(this)
        }
    },
    mounted() {
        // if (this.givenValue == "") {
        //   this.$refs.inputText.focus();
        // }
    },
    created() {
        var self = this
        setTimeout(function() {
            self.$refs.inputText.focus()
        }, 20)
    },
    beforeDestroy() {
        this.$emit('input', this.value)
        clearTimeout(this.timer)
        this.timer = null
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.input-container {
    @include display-flex();
    width: 100%;
    height: 100%;
    @include justify-content();
    .input-ticketing {
        width: 400px;
        height: 60px;
        left: 342px;
        top: 281px;
        border-bottom: none;
        padding: 1px 0;
        padding-left: 57px;
        @include font-size('l');
        @include border-radius($default-border-radius);
        @include background($image: img('keyboard_aaaaaa.svg'), $color: #f6efee, $position: left 12px center, $size: 30px);
    }
    .input-ticketing:hover {
        border-color: #f6efee;
    }
}
</style>
