<template>
    <div class="portrait-top-menu">
        <div class="top-menu">
            <div class="category-collections" v-if="(!categorySelected && Number.isInteger(collectionSelected) && !allCategorySelected && search && !stateSelected && !allStates && Object.keys(stateToSelect).length == 0) || (!categorySelected && Number.isInteger(collectionSelected) && !allCategorySelected && !stateSelected && Object.keys(stateToSelect).length == 0 && !allStates)">
                <div class="label" @click="returnToCategories()" :class="{ selected: categories && !search && !states }"></div>
                <div class="state" @click="statesSwitch()" :class="{ selected: states }"></div>
                <div class="collection" v-if="Object.values(hasCollections).length > 0" @click="returnToCollections()" :class="{ selected: collections }"></div>
            </div>
            <div class="category-selected" v-else>
                <div v-if="categorySelected || allCategorySelected" class="return-button" @click="returnToCategories()"></div>
                <div v-if="Object.keys(stateToSelect).length > 0 || allStates" class="return-button" @click="returnToStates()"></div>
                <div v-if="collectionSelected != -1" class="return-button" @click="returnToCollections()"></div>

                <div
                    class="name-category"
                    :style="{
                        backgroundColor: categorySelected.color ? categorySelected.color : '#374673'
                    }"
                >
                    <div
                        class="color"
                        @click="returnToCategories()"
                        v-if="categorySelected || allCategorySelected"
                        :style="{
                            backgroundColor: categorySelected.color ? categorySelected.color : '#374673'
                        }"
                    ></div>
                    <div class="name" @click="returnToCategories()" v-if="categorySelected || allCategorySelected">
                        {{ categorySelected.name ? categorySelected.name : $t('portrait_top_menu.all_categories') }}
                    </div>
                    <div class="name" v-else-if="Object.keys(stateToSelect).length > 0 || allStates" @click="returnToStates()">
                        {{ Object.keys(stateToSelect).length > 0 ? stateToSelect.name : $t('label.all_states') }}
                    </div>
                    <div class="name" v-else @click="returnToCollections()">
                        {{ collection.name }}
                    </div>
                </div>
            </div>
            <div class="filter-actions">
                <div class="search" v-if="!collections || search" @click="searchSwitch()" :class="{ selected: search }"></div>
            </div>
        </div>
        <div class="bottom-menu">
            <div class="search-container" v-if="search">
                <input ref="searchBarPortrait" class="search" type="text" :value="searchInput" @input="(evt) => (searchInput = evt.target.value)" />
                <div v-if="searchInput != ''" class="close" @click="cleanSearch()"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'portraitTopMenu',
    props: {
        // know if category button is active
        categories: {
            type: Boolean,
            default: false
        },
        // Know if collection is active
        collections: {
            type: Boolean,
            default: false
        },
        // Know category id selected
        categorySelected: {
            type: undefined,
            default: false
        },
        // Know collection id
        collectionSelected: {
            type: undefined,
            default: false
        },
        // Prop to know if all categgories is selected
        allCategorySelected: {
            type: Boolean,
            default: false
        },
        // text of search input prop
        previousSearchInput: {
            type: String,
            default: ''
        },
        // Saber que id de estado se ha seleccionado
        previousStateSelected: {
            type: undefined,
            default: ''
        },
        // Prop para saber si se ha clickado en 'todos los estados'
        allStates: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        collection() {
            if (this.collectionSelected) {
                return this.$store.getters['label/getCollections'][this.collectionSelected]
            }
            return false
        },
        hasCollections() {
            return this.$store.getters['label/getCollections']
        },
        stateToSelect() {
            var state = this.$store.getters['label/getLabelStates'](this.previousStateSelected)
            if (this.previousStateSelected == 'allStates') {
                return { name: this.$t('label.all_states') }
            }
            if (state == undefined) {
                return {}
            }

            return state
        }
    },
    data() {
        return {
            search: false,
            searchInput: '',
            states: false,
            stateSelected: false
        }
    },
    methods: {
        cleanSearch() {
            this.searchInput = ''
        },
        searchSwitch() {
            if (!this.search) {
                const virtualList = document.getElementsByClassName('virtual-list')[0]
                if (typeof virtualList !== 'undefined' && virtualList) {
                    virtualList.scrollTop = 0
                }
                // Si tiene una categoría previamente seleccionada, seleccionará todas las categorías
                // if (this.categorySelected) {
                //     this.$emit('isSearching', 'false')
                // }
                // this.states = false
                this.search = true
                // this.stateSelected = false
                // this.$emit('statesSwitch', false)
                // this.$emit('selectStateFilter', false)
                if (!this.categorySelected && !this.states) {
                    this.$emit('isSearching', 'allCategories')
                }
                if (this.states && !this.categorySelected) {
                    this.$emit('searchStates')
                }
                this.$nextTick(() => {
                    this.$refs.searchBarPortrait.focus()
                })
            } else {
                this.cleanSearch()
                this.search = false
                // this.$emit('isSearching', 'false')
            }
        },
        statesSwitch() {
            if (!this.states) {
                const virtualList = document.getElementsByClassName('virtual-list')[0]
                if (typeof virtualList !== 'undefined' && virtualList) {
                    virtualList.scrollTop = 0
                }

                if (this.search) {
                    this.search = false
                    this.cleanSearch()
                    this.$emit('isSearching', false)
                }
                this.states = true
                this.$emit('statesSwitch', true)
                this.$emit('setTabulation', 1)
                this.$store.commit('label/setPortraitDefaultTab', 'states')
            } else {
                this.states = false
                this.stateSelected = false
                this.$emit('statesSwitch', false)
            }
        },
        selectStateFilter() {
            this.$emit('selectStateFilter', this.stateSelected)
        },
        returnToStates() {
            this.states = true
            if (this.allStates) {
                this.$emit('statesSwitch', 'return')
            }
            this.search = false
            this.stateSelected = false
            this.cleanSearch()
            this.$emit('isSearching', false)
            this.$emit('statesSwitch', true)
            this.$emit('setTabulation', 1)
            this.$store.commit('label/setPortraitDefaultTab', 'states')
        },
        returnToCategories() {
            if (this.search) {
                this.search = false
                this.cleanSearch()
                this.$emit('isSearching', false)
            }
            this.states = false
            this.stateSelected = false
            this.$emit('statesSwitch', false)
            this.$emit('setTabulation', 1)
            this.$emit('returnToCategories', false)
            const categoryContainer = document.getElementsByClassName('categories-container')[0]
            if (typeof categoryContainer !== 'undefined' && categoryContainer) {
                categoryContainer.scrollTop = 0
            }
            this.$store.commit('label/setPortraitDefaultTab', 'categories')
        },
        returnToCollections() {
            if (this.search) {
                this.search = false
            }
            this.$bar.reset()
            this.$bar.hide()
            this.states = false
            this.stateSelected = false
            this.$emit('statesSwitch', false)
            this.$emit('returnToCollections')
            this.$emit('setTabulation', 2)
            const categoryContainer = document.getElementsByClassName('categories-container')[0]
            if (typeof categoryContainer !== 'undefined' && categoryContainer) {
                categoryContainer.scrollTop = 0
            }
        }
    },
    watch: {
        searchInput(newValue) {
            this.$emit('searchInput', newValue)
        }
    },
    beforeRouteLeave(to, from, next) {
        this.search = false
        this.$emit('isSearching', 'false')
        this.returnToCategories()
        next()
    },
    beforeDestroy() {
        // this.search = false
        // this.$emit('isSearching', 'false')
        // this.returnToCategories()
    },
    created() {
        // get last tab from user, if is states, select it
        const portraitDefaultTab = this.$store.getters['label/getPortraitDefaultTab']
        if (portraitDefaultTab && portraitDefaultTab == 'states') {
            this.statesSwitch()
        }

        if (this.previousSearchInput != '') {
            this.search = true
            this.searchInput = this.previousSearchInput
        }
        if (this.previousStateSelected != '') {
            this.states = true
            this.stateSelected = this.previousStateSelected
        }
    }
}
</script>

<style lang="scss" scoped>
.portrait-top-menu {
    @include display-flex();
    @include justify-content(space-between);
    @include flex-direction(column);
    width: 100%;
    height: 100%;

    .top-menu {
        @include display-flex();
        @include justify-content(space-between);
        width: 100%;
        height: 100%;

        .category-collections {
            @include display-flex();
            .label {
                @include background($image: img('collection_list_portrait.svg'), $color: #fff, $size: 25px);
                @include border-radius(4px);
                height: 45px;
                width: 45px;
                margin-right: 10px;
                cursor: pointer;

                &.selected {
                    @include background($image: img('collection_list_portrait_active.svg'), $color: $main-dark, $size: 25px);
                }
            }

            .state {
                @include background($image: img('state_neutro.svg'), $color: #fff, $size: 25px);
                @include border-radius(4px);
                height: 45px;
                width: 45px;
                margin-right: 10px;
                cursor: pointer;
                &.selected {
                    @include background($image: img('state_ffffff.svg'), $color: $main-dark, $size: 25px);
                }
            }

            .collection {
                @include background($image: img('collection_neutro.svg'), $color: #fff, $size: 25px);
                @include border-radius(4px);
                height: 45px;
                width: 45px;
                cursor: pointer;

                &.selected {
                    @include background($image: img('collection_ffffff.svg'), $color: $main-dark, $size: 25px);
                }
            }
        }

        .category-selected {
            @include display-flex();
            @include flex-direction(row);
            width: auto;
            height: 45px;
            cursor: pointer;
            $size-bolita: rem(18px);

            .return-button {
                @include background($image: img('left_dark.svg'), $color: #fff, $size: $size-bolita);
                @include border-radius(5px 0 0 5px);
                width: 45px;
                height: 45px;
            }

            .name-category {
                @include display-flex();
                @include align-items(center);
                @include border-radius(0px 5px 5px 0);
                width: auto;
                height: 45px;
                align-self: center;
                padding-left: 10px;
                padding-right: 10px;

                .color {
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    min-height: 18px;
                    border-radius: 50%;
                    filter: brightness(70%);
                    margin-right: 10px;
                }
                .name {
                    @include display-flex();
                    @include align-items(center);
                    @include text-ellipsis();
                    font-family: $text-sbold;
                    display: inline-block;
                    width: 100%;
                    color: #fff;
                    height: auto;
                    max-width: 45vw;
                    // max-width: 120px;
                }
            }
        }
        .filter-actions {
            @include display-flex();

            .search {
                @include background($image: img('search_neutro.svg'), $color: #fff, $size: 25px);
                @include border-radius(4px);
                height: 45px;
                width: 45px;
                cursor: pointer;

                &.selected {
                    @include background($image: img('search_light.svg'), $color: $main-dark, $size: 25px);
                }
            }
        }
    }

    .bottom-menu {
        width: 100%;
        height: auto;
        padding-top: 10px;
        .search-container {
            position: relative;
            width: 100%;
            margin: 0;

            .search {
                @include display-flex();
                @include align-items(center);
                @include background($image: img('search_neutro.svg'), $size: 25px, $position: left 12px center);
                @include border-radius(4px);
                @include font-size(ml);
                height: 45px;
                width: 100%;
                background-color: #fff;
                padding: 0 60px 0 45px;
                border-bottom: none;
                font-family: $text;
            }

            .close {
                @include border-radius(4px);
                @include background($image: img('close_dark.svg'), $size: 12px, $position: center center);
                width: 33px;
                height: 33px;
                position: absolute;
                top: 7px;
                right: 10px;
                background-color: $neutro-t50;
                cursor: pointer;
            }
        }

        .states-container {
            @include background($image: img('filter_neutro.svg'), $color: #fff, $size: 25px, $position: left 12px center);
            z-index: 3;
            width: 100%;
            height: 100%;
            padding-left: 40px;
            .states {
                @include background($image: img('down_caret_dark.svg'), $color: #fff, $size: 8px, $position: right 12px center);
                height: 45px;
                width: 100%;
                border-bottom: none !important;
                background-color: #fff;
                padding-top: 5px;
                font-family: $text-light;

                &:focus:not(:active) {
                    @include background($image: img('up_caret_dark.svg'), $color: #fff, $size: 8px, $position: right 12px center);
                    background-color: #fff;
                }
            }
        }
    }
}
</style>
