<template>
    <div class="referenceState" :class="[{ selected: selected }, $mq]" @click="updateSelected(!selected)">
        <div class="image" :style="referenceImage != false && connection() ? { backgroundImage: 'url(' + referenceImage + ')' } : {}"></div>
        <div class="status select" v-if="['portrait'].includes($mq)" :class="{ selected: selected }"></div>

        <div class="text">
            <div class="name">{{ referenceName }}</div>
            <div class="status"><template v-if="['landscape', 'desktop'].includes($mq)">#</template> {{ state.name.toUpperCase() }}</div>
        </div>

        <div class="information" v-if="['landscape', 'desktop'].includes($mq)">
            <div class="status" :class="{ selected: selected }"></div>
            <div class="quantity">x{{ state.quantity }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ReferenceState',
    props: ['state', 'collectionID', 'referenceID', 'referenceName', 'referenceImage'],
    computed: {},
    data() {
        return {
            selected: true,
            cssImage: css_image_url
        }
    },
    created() {
        this.updateSelected(true)
    },
    methods: {
        updateSelected(selected) {
            // on click, change status
            this.selected = selected

            // save status in store
            this.$store.commit('label/referenceStateSelected', {
                collectionID: this.collectionID,
                referenceID: this.referenceID,
                stateID: this.state.id,
                selected: this.selected
            })

            // emit select is changed to show print button
            this.$emit('showPrintButton')
        },

        connection() {
            return fnCheckConnection()
        }
    },
    mounted() {}
}
</script>

<style lang="scss">
.referenceState {
    @include interaction();
    @include display-flex();
    @include border-radius(5px);
    @include align-items();
    min-height: 60px;
    height: fit-content;
    padding: 9px;
    opacity: 0.5;
    background-color: #fff;

    @include bgHover(#fff);

    &.selected {
        opacity: 1;
    }

    .image {
        @include background($image: img('no_icon_light.svg'), $size: 40px, $position: center center);
        width: 50px;
        height: 50px;
        display: inline-block;
    }

    .text {
        // @include display-inline-flex();
        display: grid !important;
        flex-wrap: wrap;
        min-height: 24px;
        width: calc(100% - 50px - 24px);

        .name {
            @include text-ellipsis($line: 2);
            @include font-size(m);
            font-family: $text-bold;
            width: 100%;
        }
        .status {
            @include text-ellipsis();

            @include font-size(sm);
            width: 100%;
        }
    }

    .information {
        @include display-inline-flex();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        min-width: 20px;
        width: 24px;
        height: 100%;
        .status {
            @include background($image: img('error_aaaaaa.svg'), $size: 20px, $position: center center);
            width: 100%;
            height: 20px;
            margin-bottom: 6px;
            &.selected {
                @include background($image: img('checked_ok.svg'), $size: 20px, $position: center center);
            }
        }
        .quantity {
            @include font-size(m);
            width: 100%;
            height: 24px;
            text-align: center;
            font-family: $conden-bold;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.referenceState.portrait {
    position: relative;
    height: min-content;

    .image {
        @include background($image: img('no_icon_light.svg'), $size: 30px, $position: top center);
        width: 50px;
        height: 50px;
        display: inline-block;
    }
    .status.select {
        position: absolute;
        bottom: 0;
        left: 0;
        @include background($image: img('error_aaaaaa.svg'), $size: 20px, $position: center center);
        width: 70px;
        height: 25px;
        margin-bottom: 6px;
        &.selected {
            @include background($image: img('checked_ok.svg'), $size: 20px, $position: center center);
        }
    }

    .text {
        @include align-content(space-between);
        width: calc(100% - 60px);
        height: 100%;
        padding-left: 10px;

        .name {
            @include display-flex();
            @include align-items();
            @include font-size(s);
            height: 50px;
            line-height: 20px;
        }

        .status {
            font-family: $text-light;
            @include font-size(xs);
        }
    }
}
</style>
