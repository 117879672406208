<template>
    <div class="barcode-container" :class="$mq">
        <template v-if="preview">
            <div class="preview">
                <div class="label">{{ $t('label.barcode.preview') }}</div>
                <div class="value">{{ timestamp }}</div>
                <!-- <VueBarcode :value="timestamp" class="barcode-show" width="3" height="60"></VueBarcode> -->
                <VueQRCodeComponent :text="timestamp" size="90" error-level="L" class="qrcode"> </VueQRCodeComponent>

                <div class="btn back" @click="restart()">{{ $t('label.barcode.back') }}</div>
            </div>
        </template>
        <template v-else>
            <template v-if="mode == false">
                <div class="btns">
                    <div class="btn bc-read" @click="changeMode('auto')">{{ $t('label.barcode.auto') }}</div>
                    <div class="btn bc-manual" @click="mode = 'manual'" @>{{ $t('label.barcode.manual') }}</div>
                </div>
            </template>
            <template v-if="mode == 'auto'">
                <div class="scanner-windows">
                    <StreamBarcodeReader v-if="scan" @decode="onDecode"></StreamBarcodeReader>
                    <!-- <div class="button cancel" @click="mode = false"></div> -->
                    <div class="back" @click="mode = false">{{ $t('label.barcode.back') }}</div>
                </div>
                <!-- <div class="scanner">
                <div class="scan-box"></div>
                <div class="button change" @click="swapCamera()">{{ $t('label.barcode.change_camera') }}</div>
                <div class="button cancel" @click="mode = false">{{ $t('label.barcode.cancel') }}</div>
            </div> -->
            </template>
            <template v-if="mode == 'manual'">
                <div class="manual">
                    <div class="label">{{ $t('label.barcode.manual_description') }}</div>
                    <div class="input-container">
                        <input ref="inputText" type="number" v-bind:value="timestamp" @input="updateValue($event.target.value)" class="input-ticketing" @focus="handleFocus" @blur="handleBlur" />
                    </div>
                    <div class="back" @click="mode = false">{{ $t('label.barcode.back') }}</div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
import VueQRCodeComponent from 'vue-qr-generator'
import VueBarcode from 'vue-barcode'

export default {
    components: { StreamBarcodeReader, VueBarcode, VueQRCodeComponent },
    props: {
        givenValue: { default: false }
    },
    data() {
        return {
            mode: false,
            timestamp: '',
            scan: false,
            preview: false,
            loading: true,
            counter: 0,
            value: ''
        }
    },
    computed: {
        isApp() {
            // return true
            return process.env.VUE_APP_IS_APP == 'TRUE'
        },

        currentCamera() {
            return this.$store.getters['getCurrentCamera']
        }
    },
    methods: {
        // GENERAL METHODS
        updateValue(value, auto_scan = false) {
            // console.log(!moment(value * 1000).isValid() || value.toString().length != 10)
            // if (!moment(value * 1000).isValid() || value.toString().length != 10) {
            //     this.$emit('sendValue', '')
            // } else
            this.$emit('sendValue', value)
            if (auto_scan) {
                this.$emit('nextStep')
            }
        },

        load() {
            if (this.givenValue && this.givenValue !== '') {
                this.timestamp = this.givenValue
                // this.mode = 'manual'
            }
        },

        changeMode(mode) {
            if (mode == 'auto') {
                if (this.isApp) {
                    this.startBarcode()
                } else {
                    this.scan = true
                    this.mode = 'auto'
                }
            } else if (mode == 'manual') {
                this.mode = 'manual'
            }
        },
        restart() {
            this.preview = false
            this.timestamp = ''
            this.updateValue('')
        },

        // WINDOWS BARCODE AUTO SCANNER METHODS
        onDecode(barcode) {
            this.updateValue(barcode, true)
            this.scan = false
            this.mode = false
        },

        // APP BARCODE AUTO SCANNER METHODS
        startBarcode() {
            var self = this
            this.$store.commit('setBarcodeActive', true)
            QRScanner.prepare(
                function(err, status) {
                    if (err) {
                        // here we can handle errors and clean up any loose ends.
                        logError(err)
                    }
                    if (status.authorized) {
                        // W00t, you have camera access and the scanner is initialized.
                        // QRscanner.show() should feel very fast.
                    } else if (status.denied) {
                        // The video preview will remain black, and scanning is disabled. We can
                        // try to ask the user to change their mind, but we'll have to send them
                        // to their device settings with `QRScanner.openSettings()`.
                    } else {
                        // we didn't get permission, but we didn't get permanently denied. (On
                        // Android, a denial isn't permanent unless the user checks the "Don't
                        // ask again" box.) We can ask again at the next relevant opportunity.
                    }
                    // console.log('BARCODE READ')
                    if (self.$store.getters['printer/getFirstBackButton']) {
                        self.$store.commit('printer/setFirstBackButton', false)
                        document.addEventListener(
                            'backbutton',
                            function(e) {
                                self.destroyBarcode()
                            },
                            true
                        )
                    }
                    QRScanner.scan(self.displayContents, { formats: ['QR_CODE', 'CODE_39', 'CODE_128'] })
                    QRScanner.show(function(status) {
                        log(status)
                    })
                    QRScanner.useCamera(self.currentCamera ? self.currentCamera : 0, function(err, status) {
                        log(status)
                    })
                },
                { formats: ['QR_CODE', 'CODE_39', 'CODE_128'] }
            )
        },

        // prepareBarcode() {
        //     QRScanner.prepare(
        //         function(err, status) {
        //             if (err) {
        //                 // here we can handle errors and clean up any loose ends.
        //                 console.error(err)
        //             }
        //             if (status.authorized) {
        //                 // W00t, you have camera access and the scanner is initialized.
        //                 // QRscanner.show() should feel very fast.
        //             } else if (status.denied) {
        //                 // The video preview will remain black, and scanning is disabled. We can
        //                 // try to ask the user to change their mind, but we'll have to send them
        //                 // to their device settings with `QRScanner.openSettings()`.
        //             } else {
        //                 // we didn't get permission, but we didn't get permanently denied. (On
        //                 // Android, a denial isn't permanent unless the user checks the "Don't
        //                 // ask again" box.) We can ask again at the next relevant opportunity.
        //             }
        //         },
        //         { formats: ['QR_CODE', 'CODE_39', 'CODE_128'] }
        //     )
        // },

        displayContents(err, barcode) {
            // console.log('DISPLAY CONTENTS')
            if (err) {
                logError(err)
            } else {
                if (this.loading) {
                    // Discart first readings
                    this.counter += 1
                    if (this.counter == 4) this.loading = false

                    var self = this
                    QRScanner.scan(self.displayContents, { formats: ['QR_CODE', 'CODE_39'] })
                } else {
                    // Start reading codes
                    if (this.value == barcode) {
                        this.counter += 1
                    } else {
                        this.value = barcode
                        this.counter = 1
                    }

                    if (this.counter < 7) {
                        var self = this
                        QRScanner.scan(self.displayContents, { formats: ['QR_CODE', 'CODE_39'] })
                    } else {
                        // The scan completed, display the contents of the QR code:
                        this.updateValue(barcode, true)
                        this.destroyBarcode()
                        // TODO - Remove confirmation page
                        this.preview = false
                    }

                    // this.destroyBarcode()
                    // this.preview = true
                    // destroy
                }
            }
        },

        // scan() {
        //     QRScanner.scan(displayContents, { formats: ['QR_CODE', 'CODE_39', 'CODE_128'] })
        // },

        // showCamera() {
        //     QRScanner.show(function(status) {
        //         console.log(status)
        //     })
        // },

        // hideCamera() {
        //     QRScanner.hide(function(status) {
        //         console.log(status)
        //     })
        // },

        destroyBarcode() {
            this.loading = true
            this.counter = 0
            this.value = ''
            QRScanner.disableLight(function(err, status) {
                log(status)
            })
            this.$store.commit('setBarcodeActive', false)
            // TODO - Uncomment
            // QRScanner.destroy(function(status) {})
        },

        // MANUAL BARCODE INPUT METHODS

        handleFocus(event, atElement) {
            let id = this.$refs.inputText
            atElement = typeof atElement === 'undefined' ? false : atElement

            this.$emit('handleFocus', true)
            this.$root.handleFocusKeyboard(this)
        },
        handleBlur(e) {
            this.$emit('handleFocus', false)
            this.$root.handleBlurKeyboard(this)

            // this.displayContents(false, e.target.value)
        }
    },
    mounted() {
        // if (!this.isApp) {
        //     this.mode = 'manual'
        // }
    },
    beforeDestroy() {
        this.destroyBarcode()
    },
    created() {},
    updated() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.barcode-container {
    @include display-flex();
    @include justify-content();
    width: 100%;
    text-align: center;
    margin-top: rem(18px);
    height: 100%;
    min-height: 50vh;

    .btns {
        @include display-flex();
        @include flex-wrap(wrap);
        @include flex-direction(column);
        @include justify-content();

        .btn {
            font-family: $text-bold;
            margin: 15px auto;
            width: 100%;
            min-width: 300px;
            text-align: center;
            padding: 22px;
        }

        .bc-read {
            @include background($image: img('qr_light.svg'), $position: center left 20px, $size: 35px);
            padding-left: 55px;
        }

        .bc-manual {
            background-color: #f6efee;
            color: $main;
        }
    }

    .scanner {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999999999999999999;

        .scan-box {
            background-color: grey;
            width: 500px;
            height: 500px;
            top: calc(50% - 250px);
            left: calc(50% - 250px);
            position: absolute;
        }

        .button {
            @include border-radius(6px);
            @include font-size('m');
            font-family: $text;
            position: absolute;
            min-width: 200px;
            min-height: 60px;
            bottom: 0px;
            padding: 20px 20px;
            margin: 30px;
            background-color: #fff;
            text-align: center;
            cursor: pointer;

            &.change {
                left: 0;
            }

            &.cancel {
                right: 0px;
            }
        }
    }

    .manual {
        @include display-flex();
        @include justify-content();
        @include flex-direction(column);
        gap: 30px;

        .back {
            max-width: 300px;
        }
    }

    .label {
        @include font-size('m');
        color: $neutro-s70;
        text-align: center;
        padding: 0 20px;
    }

    .preview {
        text-align: center;
        width: 100%;
        @include display-flex();
        @include justify-content();
        @include flex-direction(column);
        gap: 30px;

        .value {
            text-align: center;
            font-family: $text-bold;
            @include font-size('xl');
        }
        .barcode-show {
            text-align: center;
            @include border-radius(5px);
        }

        .qrcode {
            @include border-radius(5px);
            background: #fff;
            padding: 10px;
            align-self: center;
        }
    }

    .input-container {
        @include display-flex();
        @include justify-content();
        width: 100%;
        height: auto;

        .input-ticketing {
            @include font-size('l');
            @include border-radius($default-border-radius);
            @include background($image: img('input_number_cccccc.svg'), $color: #f6efee, $position: left 12px center, $size: 30px);
            width: 270px;
            height: 60px;
            left: 342px;
            top: 281px;
            border-bottom: none;
            padding: 1px 0;
            padding-left: 57px;
            // text-align: center;
        }
        .input-ticketing:hover {
            border-color: #f6efee;
        }
    }

    .back {
        @include border-radius(6px);
        @include font-size('sm');
        font-family: $text-bold;
        background-color: $default-sec-color;
        color: $info-s60;
        margin: 0 auto;
        // margin-top: 80px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>

<style lang="scss">
$scanner-height: calc(61vh);
.scanner-windows {
    position: relative;
    height: $scanner-height;
    overflow: hidden;
    // width: 100%;
    max-width: 100%;
    min-width: 100px;
    min-height: 50vh;
    @include background($image: img('loader2_primary.svg'), $position: center center, $size: 100px);

    .scanner-container {
        width: 100%;
        height: $scanner-height;

        & > div {
            width: 100%;
            height: $scanner-height;

            & > video {
                width: 100%;
                height: $scanner-height;
            }

            & > .overlay-element {
                width: 100%;
                height: $scanner-height;
                -webkit-clip-path: polygon(0% 0%, 0% 100%, 10% 100%, 10% 35%, 90% 35%, 90% 60%, 10% 60%, 10% 100%, 100% 100%, 100% 0%) !important;
                clip-path: polygon(0% 0%, 0% 100%, 10% 100%, 10% 35%, 90% 35%, 90% 60%, 10% 60%, 10% 100%, 100% 100%, 100% 0%) !important;
            }
        }
    }

    .cancel {
        &.cancel {
            @include border-radius(100%);
            @include background($image: img('close_dark.svg'), $size: 20px, $position: center center);
            position: absolute;
            z-index: 99999;
            top: 10px;
            right: 10px;
            height: 50px;
            width: 50px;
            background-color: #fff;
            cursor: pointer;
        }
    }

    .back {
        @include font-size('sm');
        // @include border-radius(0 0px 6px 0px);
        z-index: 99999;
        position: absolute;
        font-family: $text-bold;
        background-color: #f6efee;
        color: $info-s60;
        margin: 0 auto;
        // top: 0;
        bottom: 0;
        left: 0;
        // margin-top: 80px;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        padding: 20px 0;
        cursor: pointer;
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.barcode-container.portrait {
    margin-top: 0;
    height: calc(100%);
    .btns {
        width: 100%;
        .btn {
            @include font-size(s);
        }

        .bc-read {
        }

        .bc-manual {
            background-color: #fff;
        }
    }

    .scanner {
        .scan-box {
        }

        .button {
            &.change {
            }

            &.cancel {
            }
        }
    }

    .scanner-windows {
        .back {
            background-color: $default-sec-color;
        }
    }

    .manual {
        @include justify-content(space-evenly);
    }
    .label {
        @include font-size(s);
        margin-bottom: 0;
    }

    .input-container {
        .input-ticketing {
            width: 300px;
            background-color: #fff;
        }
        .input-ticketing:hover {
        }
    }

    .back {
        margin-top: 0;
        background-color: $default-sec-color;
    }

    .preview {
        .value {
            @include font-size('l');
        }

        .barcode-show {
        }
    }
}
</style>
