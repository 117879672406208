<template>
    <div :class="[$mq, type]" class="single-label">
        <!-- {{ labels }} -->
        <div
            class="image"
            :style="{
                backgroundImage: 'url(' + (labels[label.product].local_image ? labels[label.product].local_image : labels[label.product].image) + ')'
            }"
        ></div>
        <div class="info" :class="{ is_printing: is_printing }">
            <div class="name">
                {{ labels[label.product].name }}
            </div>
            <div class="state">
                {{ state.name }}
            </div>
            <div class="meta">
                <div class="quantity">{{ label.quantity }}</div>
                <div class="date">{{ type == 'preview' ? parseDate(label.current_print_time) : parseDateRelative(label.current_print_time) }}</div>
            </div>
        </div>
        <div v-if="!is_printing" class="action" :class="type" @click="discardLabel()"></div>
    </div>
</template>

<script>
export default {
    name: 'singleLabel',
    props: {
        label: {
            type: [Object, Boolean],
            default: false
        },
        type: {
            type: String,
            default: 'discard'
        },
        is_printing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            queue: {}
        }
    },
    computed: {
        labels() {
            return this.$store.getters['label/getLabelsOrdered']
        },

        state() {
            return this.$store.getters['label/getLabelStates'](this.label.state)
        }
    },
    methods: {
        parseDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm')
        },
        parseDateRelative(date) {
            return formatRelative(date)
            return moment(date).fromNow()
        },

        discardLabel() {
            this.$emit('discard')
        }
    },
    created() {},
    watch: {}
}
</script>

<style lang="scss" scoped>
.single-label {
    @include display-flex();
    @include align-items();
    @include justify-content(space-between);
    @include border-radius(5px);
    background-color: #fff;
    width: 50%;
    width: 100%;
    padding: 10px;
    height: max-content;
    min-height: 110px;
    overflow: auto;
    cursor: pointer;

    .image {
        @include background($size: contain, $position: center center);
        height: 80px;
        width: 80px;
    }

    .info {
        width: calc(100% - 80px - 50px);

        &.is_printing {
            width: calc(100% - 80px - 10px);
        }

        .name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $neutro-s90;
            @include text-ellipsis();
        }

        .state {
            @include font-size(s);
            font-family: $text-light;
            text-transform: uppercase;
            @include text-ellipsis();
        }

        .meta {
            @include display-flex();
            @include align-items();
            @include justify-content(flex-start);

            .quantity,
            .date {
                color: $neutro-s50;
                @include font-size(s);
                font-family: $text-light;
                &::first-letter {
                    text-transform: uppercase;
                }
            }

            .quantity {
                @include background($image: img('label_neutro_s10.svg'), $size: 12px, $position: left center);
                padding-left: 15px;

                &::after {
                    width: 3px;
                    height: 3px;
                    background: $neutro-s20;
                    @include border-radius(100%);
                    content: ' ';
                    display: inline-block;
                    margin: 0 10px;
                    top: -2.5px;
                    position: relative;
                }
            }

            .date {
                @include text-ellipsis();
            }
        }
    }

    .action {
        @include background($image: img('close_main_ffffff.svg'), $color: $error-dark, $size: 18px, $position: center center);
        @include border-radius(4px);
        width: 40px;
        height: 40px;
        cursor: pointer;

        &.discard {
            @include background($image: img('close_main_ffffff.svg'), $color: $error-dark, $size: 18px, $position: center center);
        }
        &.preview {
            @include background($image: img('maximize.svg'), $color: $main-t90, $size: 18px, $position: center center);
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.single-label.portrait {
    .info {
        .name {
            @include font-size(s);
        }
        .state {
            @include font-size(xs);
        }

        .meta {
            .quantity {
                @include font-size(xs);
            }
            .date {
                @include font-size(xs);
            }
        }
    }
}
</style>
